import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/devops_success.jpg'
import person from '../../images/person.png'

const pageTitle = "Test Automation & DevOps Success";
const pageSlug = "test-automation-and-devops-success";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="digital-consulting-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle"></h6>
        </div>
      </section>

      <section id="p_digital_solutions" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    DevOps can create a culture where operation and development processes build, 
                    test, and deploy applications to increase product delivery speeds. According to 
                    experts in the field, DevOps practices can now surpass and outperform Quality 
                    Assurance processes.
                    </h3>
                    <span className="break"></span>
                <h4>Test automation throughout your organization</h4>
                <p>DevOps makes quality a shared concern for all personnel in your organization. Though 
                    these capabilities sound great, it can lead to issues in managing and prioritizing the 
                    quality control process. Without a consistent framework, quality can become marginalized, 
                    as no group is prepared to take responsibility, test automation is the solution.</p>
                <p>Successful implementation of test automation requires expert experience. The majority of work 
                    in test automation is in the setup. At Nuerex, our experts can help manage and complete your 
                    installation and determine critical factors.</p>
                    <span className="break"></span>
                <h4>DevOps and Quality Assurance</h4>
                <p>The positioning of QA in a DevOps and agile organization is not clearly defined. Most organizations 
                    bundle the service in other fields such as, continuous development, continuous integration, and 
                    continuous delivery. However, there are far more efficient ways of handling the matter.</p>
                <p>Test automation can allow for greater capabilities, as the number of tests organizations 
                    need to run is increasing exponentially.</p>
                <p> In our market of constant regression runs and testing, test automation can simplify the process 
                    and eliminate the need for manual testing, which is vital in successful DevOps implementation.</p>
                    <span className="break"></span>
                <h4>Test automation engineers</h4>
                <p>Test automation requires a skill set that is difficult to obtain due to its technical complexity. 
                    Lacking test automation can wound DevOps projects. Test automation engineers need to understand the 
                    application functionality as well as the underlying technological landscape, tools, and scripts that 
                    are required for development. As well as how the scripts can be executed with CI/CD tools to align 
                    with development processes and operations. If your organization doesn't have a qualified test automation 
                    engineer, you have three options:</p>
                <ul>
                    <li>1. Use experts from consulting companies. Through this approach, your organization can get access 
                        to the right talent. At Nuerex, we can deliver quality services to help your organization flourish.</li>
                    <li>2. Hire the staff. Finding the right people can take significant time due to the scarcity of relevant 
                        resources.</li>
                    <li>3. Retrain staff into test automation engineers. This approach takes time, however, it allows you 
                        to improve your team's abilities and skill sets</li>
                </ul>
                <span className="break"></span>
                <h4>What's Next?</h4>
                <p>To successfully implement DevOps, your development and test automation engineers need to work together. 
                    To help automation testing, develop test scripts, and maximize test coverage. The codes and scripts 
                    should be supported by CI/CD tools to generate builds automatically as well as deploying and testing 
                    them.</p>
                <p>Test automation can assume a strategic role in your organization; its capabilities include fine-tuning 
                    requirements, defining strategies, and implementing & analyzing quality metrics. Which will allow your 
                    organization not only to find issues but to prevent issues.</p>
                <p>Though the process may seem complicated, its benefits far outway any difficulties. DevOps can allow your 
                    whole business to produce better software faster, for DevOps to succeed, it needs test automation. 
                    Having an effective test automation solution in your organization is in indicator that your a mature 
                    DevOps organization, and is crucial to success. </p>
                </div>
                {/* <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default BusinessConsulting
